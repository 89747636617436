import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getServerHelpers } from "../../helpers";
import { GENDERS, OpenOrgEventId, OpenOrgEventRegistration, Team, TeamId } from "@ollie-sports/models";
import {
  ObjectKeys,
  OpenOrgEventGetRegistrationProps,
  RegistrationStatus,
  getPrettyRegistrationStatus,
  getRegistrationStatus,
  shouldSendEmail
} from "../../utils";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";
import { OptionalQuery } from "@ollie-sports/firebase-lift";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function openOrgEvents__server__exportTeamOffers(
  p: Omit<OpenOrgEventGetRegistrationProps, "openOrgEventIds"> & { email: string; openOrgEventIds: OpenOrgEventId[] }
) {
  // SERVER_ONLY_TOGGLE
  const {
    injectedServerLibraries: { sendGrid },
    appOllieFirestoreV2: h,
    getAppPgPool
  } = getServerHelpers();

  const gendersArray = _.compact(ObjectKeys(p.genders ?? [])) as GENDERS[];
  const birthYearArray = _.compact(ObjectKeys(p.birthYears ?? [])) as string[];

  const query: OptionalQuery<Team>[] = [
    {
      orgId: ["==", p.orgId]
    }
  ];
  if (gendersArray.length) {
    query.push({ gender: ["in", gendersArray] });
  }
  if (birthYearArray.length) {
    query.push({ birthYear: ["in", birthYearArray] });
  }
  if (gendersArray.length) {
    query.push({ gender: ["in", gendersArray] });
  }

  const teams = (
    await h.Team.query({
      where: query
    })
  ).docs;

  const filteredTeams = ObjectKeys(p.orgTeamTagIds ?? {}).length
    ? teams.filter(t => ObjectKeys(t.assignedOrgTagIds ?? {}).find(orgTeamTagId => !!p.orgTeamTagIds?.[orgTeamTagId]))
    : teams;

  const teamIds = filteredTeams.map(t => t.id);

  const params = [teamIds];
  if (p.openOrgEventIds.length) {
    params.push(p.openOrgEventIds);
  }

  const [accountPrivate, registrationData] = await Promise.all([
    h.AccountPrivate.getDoc(p.selfAccountId),
    await getAppPgPool().query(
      `select registration, a.action ->> 'teamId'
    from (select item as registration, jsonb_array_elements(item -> 'tryoutInfo' -> 'actions') as action
          from mirror_openorgeventregistration
          where item -> 'tryoutInfo' -> 'actions' is not null) a
    where a.action ->> 'type' <> 'favorite'
      and a.action ->> 'teamId' = any ($1::text[])${
        p.openOrgEventIds.length ? `and a.registration ->> 'openOrgEventId' = any ($2::text[])` : ""
      }
    group by a.action ->> 'teamId', registration;`,
      params
    )
  ]);

  const registrations = _.uniqBy(
    registrationData.rows.map(r => {
      return r.registration as OpenOrgEventRegistration;
    }),
    a => a.id
  );

  const registrationTeamOfferData = registrations.reduce((acc, val) => {
    const actions = val.tryoutInfo.actions ?? [];
    const actionsByTeam = _.groupBy(actions, a => a.teamId);
    Object.keys(actionsByTeam).forEach(teamId => {
      const status = getRegistrationStatus(actionsByTeam[teamId], teamId);
      if (status !== "favorited" && status !== "none") {
        acc[teamId] = [...(acc[teamId] ?? []), { registration: val, status }];
      }
    });
    return acc;
  }, {} as Record<TeamId, { registration: OpenOrgEventRegistration; status: RegistrationStatus }[]>);

  const locale = accountPrivate?.communicationLocale ?? "en-us";

  const subject = translate({ defaultMessage: `Tryout Offers`, serverLocale: locale });
  let exportString = `${translate.common(locale).Team},${translate.common(locale).Status},${translate.common(locale).ID},${
    translate.common(locale).FirstName
  },${translate.common(locale).LastName},${translate({
    defaultMessage: "Guardian First Name",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Guardian Last Name",
    serverLocale: locale
  })},${translate({ defaultMessage: "Guardian Email", serverLocale: locale })},${translate({
    defaultMessage: "Guardian Phone Number",
    serverLocale: locale
  })}\n`;

  // 1. Team
  // 2. Status
  // 3. ID
  // 4. First Name
  // 5. Last Name
  // 6. Guardian First Name
  // 7. Guardian Last Name
  // 8. Guardian Email
  // 9. Guardian Phone Number

  Object.keys(registrationTeamOfferData).forEach(teamId => {
    const team = teams.find(t => t.id === teamId);
    if (team) {
      const teamRegistrationData = registrationTeamOfferData[teamId];
      teamRegistrationData.forEach(data => {
        exportString =
          exportString +
          `${team.name},${getPrettyRegistrationStatus(data.status, locale)},${
            data.registration.playerInfo.temporaryIdentifier ?? ""
          },${data.registration.playerInfo.firstName},${data.registration.playerInfo.lastName},${
            data.registration.guardianContactInfo.firstName
          },${data.registration.guardianContactInfo.lastName},${data.registration.guardianContactInfo.email},${
            data.registration.guardianContactInfo.phoneNumber
          }\n`;
      });
    }
  });

  if (shouldSendEmail(p.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: p.email,
      subject,
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${translate({ defaultMessage: "Team Offer Export", serverLocale: locale })}</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({
      defaultMessage: "Attached to this email you will find your exported tryout offer list.",
      serverLocale: locale
    })}.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename: `TeamOffer.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "teamOffers"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${p.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

openOrgEvents__server__exportTeamOffers.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};
// i18n certified - complete
